<template>
  <div class="individual-bets">
    <div class="individual-bets__item">
      <div class="individual-bets__item__title">Apuestas Individuales</div>
      <div class="individual-bets__item__description">
        <template v-if="!isMobile">
          Realiza las 4 apuestas de forma independiente (no es parlay)
        </template>
        <template v-else> Realiza las 4 apuestas de forma <br />independiente (no es parlay) </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'IndividualBets',
  computed: {
    ...mapState(['displayWidth']),
    isMobile() {
      return this.displayWidth < 431;
    },
  },
};
</script>

<style scoped lang="scss">
.individual-bets {
  width: 100%;
  height: 85px;
  display: inline-flex;
  justify-content: center;

  &__item {
    width: 410px;
    height: 85px;
    @media screen and (max-width: 430px) {
      width: 310px;
    }
    @media screen and (max-width: 340px) {
      width: 290px;
    }
    &__title {
      width: 100%;
      height: 42px;
      font-family: 'Avenir-Pro-Bold';
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 42px;
      letter-spacing: -0.6px;
      text-align: center;
      color: #132839;
      @media screen and (max-width: 430px) {
        font-size: 26px;
      }
      @media screen and (max-width: 340px) {
        font-size: 24px;
      }
    }
    &__description {
      width: 100%;
      height: 43px;
      border-radius: 12px;
      background-color: #cbee6b;
      font-family: 'Roboto-Regular';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 43px;
      letter-spacing: -0.28px;
      text-align: center;
      color: #132839;
      @media screen and (max-width: 430px) {
        height: 63px;
        line-height: 19px;
        padding-top: 13px;
      }
    }
  }
}
</style>
